<template>
  <div>
    <div class="top">
      <img class="poster" :src="poster && $imgurl + poster" alt="" />
      <img
        class="play cursor"
        src="@/assets/images/play.png"
        alt=""
        @click="playVideo"
      />
    </div>

    <div class="block_01">
      <div class="con">
        <div class="text">
          技术投入与成果
          <img src="@/assets/img01/result_en.png" alt="" />
        </div>
        <div class="card-box">
          <div class="left">
            <div
              class="card-item"
            
              v-for="(item, index) in this.block_01.list_01"
              :key="index"
              @mouseover="mouseOver($event)"
              @mouseleave="mouseLeave($event)"
            >
              <div class="img">
                <img :src="$imgurl + item.photo.url" alt="" />
              </div>
              <div class="card-txt">
                <div class="card-item-tit">{{ item.title }}</div>
                <div class="card-intro-tit te" v-html="item.content"> 
                </div>
                <div class="card-intro-tit ts" v-html="item.content">
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div
              class="card-item"
              v-for="(item, index) in this.block_01.list_02"
              :key="index"
              @mouseover="mouseOver($event)"
              @mouseleave="mouseLeave($event)"
            >
              <div class="img">
                <img :src="$imgurl + item.photo.url" alt="" />
              </div>
              <div class="card-txt">
                <div class="card-item-tit" v-html="item.title"></div>
                <div class="card-intro-tit te" v-html="item.content">
                </div>
                <div class="card-intro-tit ts">
                  {{ stripHtmlTags(item.content) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="block1"
      v-if="block1.isShow == true ? true : false"
      :style="`background:url(${
        block1.bg && $imgurl + block1.bg
      }) center/cover no-repeat;`"
    >
      <div class="con">
        <div
          class="title"
          :style="{
            background: `url(${
              block1.t_bg && $imgurl + block1.t_bg
            }) center/contain no-repeat`,
          }"
        >
          <p>{{ block1.title }}</p>
        </div>
        <ul class="flex flex-wrap">
          <li
            v-for="(item, index) in block1.list"
            :key="index"
            class="wow fadeInUp"
            :data-wow-delay="`${index * 0.5}` + 's'"
          >
            <img class="icon" :src="$imgurl + item.url" alt="" />
            <p class="desc">{{ item.text }}</p>
          </li>
        </ul>
      </div>
    </div>

    <div class="block_02">
      <div class="con">
        <div class="text">
          {{ block_02.title }}
          <img src="@/assets/img01/result-01.png" alt="" />
        </div>
        <div class="bottom">
          <div class="left">
            <div class="t1" v-html="block_02.content">
            </div>
          </div>
          <div class="right">
            <img :src="$imgurl + block_02.photo.url" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="block_03">
      <div class="con">
        <div class="text">
          三大平台
          <img src="@/assets/img01/plant.png" alt="" />
        </div>
        <div class="bottom">
          <div class="ul-box">
            <div class="li" v-for="(item, index) in block_03.list" :key="index">
              <div class="bg">
                <img :src="$imgurl + item.photo.url" alt="" /></div>
              <div class="show_txt">
                <div class="icon">
                  <img :src="$imgurl + item.exp1.url" alt="" />
                </div>
                <div class="t1" v-html="item.title"></div>
                <div class="t2"  v-html="item.subtitle"></div>
              </div>
              <div class="hide_txt">
                <div class="icon">
                  <img :src="$imgurl + item.exp1.url" alt="" />
                </div>
                <div class="t1">{{item.title}}</div>
                <div class="t2"  v-html="item.subtitle"></div>
                <div class="t3" v-html="item.content">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div
      class="block1"
      :style="`background:url(${
        block1.bg && $imgurl + block1.bg
      }) center/cover no-repeat;`
 
      "


    >
      <div class="con">
        <div
          class="title"
          :style="{
            background: `url(${
              block1.t_bg && $imgurl + block1.t_bg
            }) center/contain no-repeat`,
          }"
        >
          <p>{{ block1.title }}</p>
        </div>
        <ul class="flex flex-wrap">
          <li
            v-for="(item, index) in block1.list"
            :key="index"
            class="wow fadeInUp"
            :data-wow-delay="`${index * 0.5}` + 's'"
          >
            <img class="icon" :src="$imgurl + item.url" alt="" />
            <p class="desc">{{ item.text }}</p>
          </li>
        </ul>
      </div>
    </div> -->

    <div
      class="block2"
      :style="`background:url(${
        block2.bg && $imgurl + block2.bg
      }) center/cover no-repeat ;`"
    >
      <div class="con">
        <div
          class="title"
          :style="{
            background: `url(${
              block2.t_bg && $imgurl + block2.t_bg
            }) center/contain no-repeat`,
          }"
        >
          <p>{{ block2.title }}</p>
        </div>
        <ul class="flex flex-wrap">
          <router-link
            tag="li"
            :to="{ path: '/lightcon', query: { ind: index, id: item.id } }"
            class="cursor wow fadeInUp"
            v-for="(item, index) in block2.list"
            :key="index"
            data-wow-delay="0.5s"
          >
            <div class="filter"></div>
            <div class="li-con flex-column-center">
              <p class="che">{{ item.title }}</p>
              <p class="eng">{{ item.entitle }}</p>
            </div>
          </router-link>
        </ul>
      </div>
    </div>

    <div class="block35">
      <div class="mar">
        <div class="text">
          技术动态
          <img src="../assets/img01/LIGHTINSIGHTS.png" alt="" />
        </div>
        <div class="box_2">
          <!-- <div class="sam_box wow fadeInUp" @click='toview'>
            <img src="../assets/img01/light_01.png" alt="" />
            <div class="txt">
              <p>金融机构智能化转型要素：算法篇</p>
              <p>
                随着量化私募崛起，券商纷纷入场布局机构服务，高性能领域成为行业军备竞赛的高地
              </p>
              <p>2020-12-27</p>
            </div>
          </div>

          <div class="sam_box wow fadeInUp">
            <img src="../assets/img01/light_02.png" alt="" />
            <div class="txt">
              <p>ArchSummit全球架构师峰会，恒生林金曙受邀担任NLP专题出品人</p>
              <p>
                随着量化私募崛起，券商纷纷入场布局机构服务，高性能领域成为行业军备竞赛的高地
              </p>
              <p>2020-12-27</p>
            </div>
          </div>

          <div class="sam_box wow fadeInUp" data-wow-delay="0.8s">
            <img src="../assets/img01/light_03.png" alt="" />
            <div class="txt">
              <p>重构，是毒药还是解药？</p>
              <p>
                随着量化私募崛起，券商纷纷入场布局机构服务，高性能领域成为行业军备竞赛的高地
              </p>
              <p>2020-12-27</p>
            </div>
          </div>

          <div class="sam_box wow fadeInUp" data-wow-delay="0.8s">
            <img src="../assets/img01/light_04.png" alt="" />
            <div class="txt">
              <p>一文读懂恒生如何做云原生</p>
              <p>
                随着量化私募崛起，券商纷纷入场布局机构服务，高性能领域成为行业军备竞赛的高地
              </p>
              <p>2020-12-27</p>
            </div>
          </div> -->

          <div
            class="sam_box wow fadeInUp"
            data-wow-delay="0.8s"
            v-for="(item, index) in block5"
            :key="index"
            @click="toview(item.id)"
          >
            <img :src="$imgurl + item.photo.url" alt="" />
            <div class="txt">
              <p>{{ item.title }}</p>
              <p v-html="item.content" ref="dom"></p>
              <p>{{ item.timeline }}</p>
            </div>
          </div>
        </div>
        <div class="MORE" @click="tonewslist">
          <img src="../assets/img01/b1-s4.png" alt="" />
          MORE +
        </div>
      </div>
    </div>

    <div
      class="block3"
      v-if="block3.isShow == true ? true : false"
      :style="`background:url(${
        block3.bg && $imgurl + block3.bg
      })  center/cover no-repeat;`"
    >
      <div class="c-con">
        <img
          class="c-big"
          :src="block3.c_img && $imgurl + block3.c_img"
          alt=""
        />
        <div
          class="abs1"
          v-for="(item, index) in block3.list.slice(0, 3)"
          :key="index"
          @click="tolink(item.link)"
        >
          <div class="title b">{{ item.title }}</div>
          <div class="desc">{{ item.content }}</div>
        </div>
      </div>
    </div>

    <div
      class="block4"
      v-if="block4.isShow == true ? true : false"
      :style="`background:url(${
        block4.exp2 && $imgurl + block4.exp2.url
      }) center/cover no-repeat;`"
    >
      <div class="con wow fadeInUp" data-wow-delay=".2s">
        <img
          class="title"
          :src="block4.photo && $imgurl + block4.photo.url"
          alt=""
        />
        <p class="desc" v-html="block4.content"></p>
      </div>
    </div>

    <div class="video" v-show="showVideo">
      <img
        class="close cursor"
        src="@/assets/images/close.png"
        alt=""
        @click="closeVideo"
      />
      <video ref="video" :src="video && $imgurl + video" controls></video>
    </div>
  </div>
</template>
<script>
let WOW = require("wowjs");
export default {
  data() {
    return {
      poster: "",
      video: "",
      showVideo: false,
      isShow: false,
      block1: {
        title: "",
        t_bg: "",
        bg: "",
        list: [],
        isShow: false,
      },
      block2: {
        title: "",
        t_bg: "",
        bg: "",
        list: [],
      },
      block3: {
        bg: "",
        c_img: "",
        list: [],
        isShow: false,
      },
      block4: { isShow: false },
      block5: [],
      block_01: { list_01: [], list_02: [] },
      block_02: { content: "", title: "", photo: "" },
      block_03: {  list: [] },
    };
  },
  mounted() {
    //获取到配置的头部颜色，听过store监听动态改变
    this.$store.commit("until/SET_COLOR", "#fff");

    //监听视频结束
    let video = this.$refs["video"];
    video.addEventListener(
      "ended",
      () => {
        //结束
        // console.log("播放结束");
        this.showVideo = false;
      },
      false
    );

    this.getData();
  },
  methods: {
    // 使用示例
    mouseOver($event) {
      $event.currentTarget.classList.remove("active");

      $event.currentTarget.classList.add("active");
    },
    mouseLeave($event) {
      // document.querySelecto  this.isShow=!this.isShow
      $event.currentTarget.classList.remove("active");
    },
    getData() {
      this.$fetch({
        url: "index.php/lightapi/initLightIndex",
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.poster = res.data.banner.photo.url;
          this.video = res.data.banner.files.url;
          this.block1.list = res.data.tab2.photo;
          this.block1.title = res.data.tab2.title;
          this.block1.t_bg = res.data.tab2.exp1[0].url;
          this.block1.bg = res.data.tab2.exp2[0].url;
          this.block1.isShow = res.data.tab2.isshow;
          this.block2.list = res.data.tab3;
          this.block2.title = res.data.tab3_0.title;
          this.block2.t_bg = res.data.tab3_0.exp1.url;
          this.block2.bg = res.data.tab3_0.photo.url;
          this.block3.isShow = res.data.tab4_1.isshow;
          this.block3.bg = res.data.tab4_1.photo.url;
          this.block3.c_img = res.data.tab4_1.exp1.url;
          this.block3.list = res.data.tab4_2;
          this.block4 = res.data.tab5;
          this.block4.isShow = res.data.tab5.isshow;
          this.block5 = res.data.tab6_1;

          this.block_01.list_01.push(res.data.tab6[0], res.data.tab6[1]);
          this.block_01.list_02.push(res.data.tab6[2], res.data.tab6[3]);
          console.log(
            this.block4.isShow,
            this.block1.isShow,
            this.block3.isShow
          );
          this.block_02.content = res.data.tab7.content;
          this.block_02.title = res.data.tab7.title;
          this.block_02.photo = res.data.tab7.photo;

          this.block_03.list = res.data.tab8;


          this.$nextTick(() => {
            let wow = new WOW.WOW({
              boxClass: "wow",
              animateClass: "animated",
              offset: 0,
              mobile: true,
              live: true,
            }).init();
            this.getxt();
          });
        }
      });
    },

    stripHtmlTags(str) {
      var parser = new DOMParser();
      
      var doc = parser.parseFromString(str, "text/html");
      
      return doc.body.textContent || "";
    },
    playVideo() {
      this.showVideo = true;
      let video = this.$refs["video"];
      video.play();
    },
    closeVideo() {
      this.showVideo = false;
      let video = this.$refs["video"];
      video.pause();
    },
    //跳详情
    toview(id) {
      this.$router.push({
        path: "/newsview",
        query: {
          id,
          crems: JSON.stringify({ title: "LIGHT技术动态", path: "/light" }),
        },
      });
    },
    //跳列表
    tonewslist() {
      this.$router.push({
        path: "/newslist",
        query: {
          ctype: 87,
          cid: 42,
        },
      });
    },
    //跳外链
    tolink(url) {
      if (url == null || url == "" || url == undefined) {
        return;
      }
      window.open(url, "_block");
    },
    getxt() {
      var arr = this.$refs.dom;
      for (var i = 0; i < arr.length; i++) {
        if (arr[i].innerText.length > 25) {
          arr[i].innerText = arr[i].innerText.slice(0, 32) + "...";
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.top {
  height: 100vh;
  position: relative;
  .poster {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }
  .play {
    width: 64px;
    height: 64px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.block_01 {
  width: 100%;
  padding: 0 0 135px;
  background-image: url(../assets/img01/result.png);
  background-position: center;


  .con {
    width: 1160px;
    margin: 0 auto;
    padding-top: 6.45833vw;

    .text {
      text-align: center;
      font-size: 36px;
      font-weight: 400;
      color: #ffffff;
      line-height: 2.60417vw;
      letter-spacing: 0.20833vw;
      margin: 0 auto 0px;
      margin-bottom: 60px;
    height: 77px;
      position: relative;
      z-index: 1;
      display: flex;
      align-items: end;
      justify-content: center;
      img {
        position: absolute;
        left: 50%;
        top: 5%;
        transform: translate(-50%, 0);
        margin: 0 auto;
        z-index: -1;
        height: 60px;
      }
    }
  }
  .card-box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .left {
      width: calc(50% - 8px);
      height: 460px;
      display: flex;
      flex-direction: column;

      .card-item.active {
        height: 360px;
      }
      .card-item {
        height: 50%;
        box-shadow: 0 4px 30px rgba(1, 25, 71, 0.1);
        border-radius: 8px;
      }
    }
    .right {
      width: calc(50% - 8px);
      height: 460px;
      display: flex;
      flex-direction: column;
      .card-item.active {
        height: 360px;
      }
      .card-item {
        height: 50%;
        box-shadow: 0 4px 30px rgba(1, 25, 71, 0.1);
        border-radius: 8px;
      }
    }
    .card-item:first-child {
      margin-bottom: 16px;
    }
    .card-item {
      
      position: relative;
      min-height: 144px;
      padding: 32px;
      height: 50%;
      transition: ease all 0.5s;
      border-radius: 16px;
   overflow: hidden;
   
      .img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .card-txt {
        position: relative;

        width: 100%;
        .card-item-tit {
          margin-bottom: 20px;
          color: #fff;
          font-weight: 400;
          font-size: 34px;
    
          line-height: 48px;
          position: relative;
          transition: all .5s;
        
        }
        .card-item-tit::after{
          position: absolute;
  bottom: -16px;
  left: 0;
  width: 0;
  height: 0;
  transition: ease all .5s;
  content: "";
        }
        .card-intro-tit {
          color: #fff;
          font-weight: 400;
          font-size: 16px;
          line-height: 28px;
          letter-spacing: 0;
          transition: all ease-in 0.5s;

        }
        .te {

          position: relative;

          transition: all .1s;


        }
        .ts {
          opacity: 0;
          position:absolute;
        visibility: hidden;;
          bottom: 0px;
        transition: all .3s;
   transition-delay: .3s;
        }
      }
    }
    .card-item .img::after{
      content: '';
    position:absolute;
      left:0;
      top:0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right ,rgba(0, 123 , 242 , .84)  , rgba(168,200 ,255 , .84)  );
    opacity: 0;
    visibility: hidden;
      }
    .card-item .img::before{
       content: '';
       position:absolute;
       left:0;
       top:0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right ,rgba(0, 46 , 111 , .84)  , rgba(4,0 ,176 , .84)  );
    opacity: 1;
    visibility: visible;
     }
    .card-item:hover {
      height: 360px;
      .card-item-tit {
        padding-bottom: 10px;
        margin-bottom: 28px;
      }
      .card-item-tit::after {
        opacity: 1;
      }
      .card-item-tit::before {
        opacity: 1;
      }
      .ts {
//position: relative;
opacity: 1;
bottom: 0;
       visibility: visible;
      }
      .te {

opacity: 0;
visibility: hidden;

      }
    }
    .card-item.active {
      height: 360px;
      .card-item-tit {
        padding-bottom: 10px;
        margin-bottom: 28px;
      }
      
      .card-item-tit::after {
        width: 172px;
        height: 1px;
        opacity: 1;
        background-color: rgba(255, 255, 255, 0.46);
      }
      .card-item-tit::before {
        opacity: 1;
        background-color: rgba(255, 255, 255, 0.46);
      }
      .ts {
//position: relative;
opacity: 1;
bottom: 0;
       visibility: visible;
      }
      .te {

opacity: 0;
visibility: hidden;

      }
    }
    .img::after{
      overflow: visible;
      opacity: 1;
    //  transition: all .3s;
    }
    .img::before{
      overflow: hidden;
      opacity: 0;
    //  transition: all .3s;
    }
  }
  .card-item.active .img::after{
  opacity: 1;
  visibility: visible;
    }
  .card-item.active .img::before{
  opacity: 0;
  visibility: hidden;
   }
   .card-item:hover .img::after{
    opacity: 1;
    visibility: visible;
      }
    .card-item:hover .img::before{
    opacity: 0;
    visibility: hidden;
     }
}

.block_02 {
  width: 100%;
  padding-bottom: 120px;
  background-image: url(../assets/img01/result_02.png);
  background-position: center;

  .con {
    width: 1160px;
    margin: 0 auto;
    padding-top: 6.45833vw;

    .text {
      text-align: center;
      font-size: 36px;
      font-weight: 400;
      line-height: 2.60417vw;
      letter-spacing: 0.20833vw;
      margin: 0 auto 60px;
      padding-top: 0;
      height: 77px;
      position: relative;
      color: #fff;
      z-index: 1;
      display: flex;
      align-items: end;
      justify-content: center;

      img {
        position: absolute;
        left: 50%;
        top: 5%;
        transform: translate(-50%, 0);
        margin: 0 auto;
        z-index: -1;
        height: 60px;

        
      }
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      min-height: 400px;
      .right {
        width: 760px;
        padding-left: 30px;
    
        img {
          width: 100%;
        }
      }
      .left {
        width: calc(100% - 790px);
      font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    text-align: justify;
    letter-spacing: 2px;
        .t1 {
          margin-bottom: 10px;
        }
        .t1,
        .t2 {
          color: #ffffff;
          line-height: 28px;
        }
      }
    }
  }
}

.block_03 {
  width: 100%;
  padding-bottom: 120px;
  background-image: url(../assets/img01/plant_bg.png);
  background-position: center;

  .con {
    width: 1160px;
    margin: 0 auto;
    padding-top: 6.45833vw;

    .text {
      text-align: center;
      font-size: 36px;
      font-weight: 400;
      line-height: 2.60417vw;
      letter-spacing: 0.20833vw;
      margin: 0 auto 100px;
      height: 77px;
      display: flex;
      align-items: end;
      justify-content: center;
      position: relative;
      color: #fff;
      z-index: 1;

      img {
        position: absolute;
        left: 50%;
        top: 5%;
        transform: translate(-50%, 0);
        margin: 0 auto;
        z-index: -1;
      height: 60px;
      }
    }
    .bottom {
      display: flex;
      justify-content: space-between;
      height: 590px;
      .ul-box {
        display: flex;
        align-items: center;
        height: 590px;
        width: 100%;
        overflow: hidden;
        border-radius: 16px;
        .li::after{
          position:absolute;
          left: 0;
          bottom: 0;
          height: 100%;
          width: 100%;
          content: '';
          background-color: rgba(0, 0 , 0 , .4);
   
        }
        .li {
          height: 100%;
          width: 33.333333%;
          transition: all 0.2s;
          position: relative;
          border-radius: 20px;
          .bg {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            z-index: 0;
            height: 100%;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .show_txt {
            position: absolute;

            left: 50%;
            transform: translateX(-50%);
            margin: 0 auto;
            padding-top: 200px;
            padding-bottom: 60px;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;
            z-index: 1;
            .icon {
              z-index: 0;
              width: 50px;
              height: 50px;
              img{
                height: 100%;
                width: 100%;
                object-fit: cover;
              }
            }
            .t1 {
              font-size: 28px;
              line-height: 1.6;
            }

            .t2 {
              font-size: 18px;
            }

            .t1,
            .t2 {
              position: relative;
              z-index: 1;
              color: #fff;
            }
          }
          .hide_txt {
            position: absolute;
            width: calc(100% );
            left: 0;
            margin: 0 auto;
            padding-left: 80px;
            padding-right: 80px;
            padding-top: 130px;
            padding-bottom: 60px;
            overflow: hidden;
            opacity: 0;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            justify-content: center;
            .icon {
              z-index: 0;
              opacity: 0;
              transition: 0.3s;
            }
            .t1 {
              font-size: 28px;
              opacity: 0;
              transition: 0.5s;
              margin-top: 8px;
            }
            .t2 {
              font-size: 18px;
              opacity: 0;
              transition: 0.5s;
              margin-top: 8px;
              margin-bottom: 22px;
            }
            .t3 {
              font-size: 16px;
              line-height: 28px;
              opacity: 0;
              transition: 0.5s;
            }
            .t1,
            .t2,
            .t3 {
              position: relative;
              z-index: 1;
              color: #fff;
            }
          }
        }
        .li:hover::after{
          opacity: 1;
          visibility: visible;
        }
        .li:hover {
         
          width: 40%;
          
        }
        .li:hover .show_txt {
          opacity: 0;
          visibility: hidden;
          transition: 0.3s;
        }
        .li:hover .hide_txt {
          opacity: 1;
          visibility: visible;
          transition: 0.3s;
          .icon {
            opacity: 1;
          z-index: 5;
          }
          .t1 {
            opacity: 1;
          }

          .t2 {
            opacity: 1;
          }
          .t3 {
            opacity: 1;
          }
        }
      }
    }
  }
}
.block1 {
  // background:url('../assets/images/img1.jpg') center/100% auto repeat-y;
  min-height: 770px;
  // background: #2C3C6B;
  padding: 103px 0 60px;
  .con {
    width: 1385px;
    margin: 0 auto;
    .title {
      height: 74px;
      padding-top: 24px;
      font-size: 36px;
      color: #ffffff;
      line-height: 50px;
      letter-spacing: 4px;
      text-align: center;
    }
    ul {
      margin-top: 40px;
      display: flex;
      justify-content: space-around;
      width: 1240px;
      margin: 0 auto;
      li {
        // margin-right: 39px;
        margin-bottom: 20px;
        &:nth-of-type(4n + 4) {
          margin-right: 0;
        }
        .icon {
          width: 300px;
          height: 289px;
          object-fit: cover;
        }
        .desc {
          margin: 8px auto 0;
          width: 226px;
          height: 140px;
          font-size: 14px;
          font-weight: 400;
          color: #b1c6ff;
          line-height: 28px;
          overflow: hidden;
        }
      }
    }
  }
}
.block2 {
  min-height: 630px;
  // background: url('../assets/images/img2.jpg') center/100% auto repeat-y;
  padding: 100px 0 60px;
  .con {
    width: 1160px;
    margin: 0 auto;
    .title {
      height: 74px;
      padding-top: 24px;
      font-size: 36px;
      color: #ffffff;
      line-height: 50px;
      letter-spacing: 4px;
      text-align: center;
    }
    ul {
      margin-top: 60px;
      text-align: center;
      justify-content: center;
      li {
        margin-right: 30px;
        margin-bottom: 30px;
        &:nth-of-type(5n + 5) {
          margin-right: 0;
        }
        width: 208px;
        height: 132px;
        position: relative;
        top: 0;
        transition: all 0.3s ease;
        &:hover {
          top: -10px;
          box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.5),
            0px 1px 45px 0px rgba(46, 75, 180, 0.42) inset;
          .li-con {
            border: 2px solid;
            border-image: linear-gradient(
                225deg,
                rgba(255, 255, 255, 0.46),
                rgba(29, 43, 80, 0)
              )
              1 1;
            color: #fff;
          }
        }
        .filter {
          width: 100%;
          height: 100%;
          filter: blur(6px);
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;
        }
        .li-con {
          width: 100%;
          height: 100%;
          background: linear-gradient(
            221deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 100%
          );
          box-shadow: 0px 1px 45px 0px rgba(46, 75, 180, 0.42) inset;
          border: 1px solid;
          border-image: linear-gradient(
              225deg,
              rgba(255, 255, 255, 0.46),
              rgba(29, 43, 80, 0)
            )
            1 1;
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1;
          color: #b1c6ff;
          .che {
            font-size: 20px;
            line-height: 44px;
          }
          .eng {
            font-size: 14px;
            line-height: 36px;
          }
        }
      }
    }
  }
}
.block3 {
  height: 622px;
  // background:url('../assets/images/l-b3-bg.png') no-repeat;
  position: relative;
  .c-con {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 75px;
    .c-big {
      width: 475px;
    }
    .abs1 {
      width: 227px;
      position: absolute;
      &:nth-of-type(1) {
        left: -266px;
        top: 157px;
      }
      &:nth-of-type(2) {
        left: -266px;
        top: 346px;
      }
      &:nth-of-type(3) {
        left: 523px;
        top: 242px;
      }
      .title {
        font-size: 20px;
        color: #ffffff;
        line-height: 30px;
        text-align: center;
      }
      .desc {
        margin-top: 12px;
        width: 227px;
        padding: 14px 19px;
        background: linear-gradient(
          221deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 0) 100%
        );
        box-shadow: 0px 1px 45px 0px rgba(46, 75, 180, 0.42) inset;
        border: 1px solid;
        border-image: linear-gradient(
            225deg,
            rgba(255, 255, 255, 0.46),
            rgba(29, 43, 80, 0)
          )
          1 1;

        font-size: 14px;
        color: rgba(255, 255, 255, 0.8);
        line-height: 22px;
        font-weight: 400;
        color: #b1c6ff;
        cursor: pointer;
      }
    }
  }
}
.block35 {
  height: 779px;
  background: url(../assets/img01/bg_txt.png) center center / cover no-repeat;
  background-position: -2px 0px;
  .mar {
    width: 1172px;
    margin: 0 auto;
    position: relative;
    .text {
      width: 290px;
      font-size: 36px;
      font-weight: 400;
      color: #ffffff;
      line-height: 50px;
      letter-spacing: 4px;
      margin: 0 auto;
      padding-top: 124px;
      position: relative;
      text-align: center;
      z-index: 1 !important;

      img {
       width: auto !important;
        height: 77px;
        position: absolute;
        bottom: -10px;
        left: 50% !important;
        transform: translateX(-50%)  ;
        z-index: -1;
      }
    }
    .box_2 {
      width: 1172px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 0 auto;
      margin-top: 55px;
      .sam_box {
        width: 574px;
        height: 210px;
        margin-bottom: 25px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        position: relative;
        top: 0;
        transition: all 0.3s ease;

        &:hover {
          top: -10px;
          box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.5),
            0px 1px 45px 0px rgba(46, 75, 180, 0.42) inset;
        }
        img {
          width: 253px;
          height: 210px;
        }
        .txt {
          width: 322px;
          height: 209px;
          background: linear-gradient(
            43deg,
            #324876 0%,
            #1d2b50 100%,
            #1d2b50 100%
          );
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          padding: 20px;
          p:nth-of-type(1) {
            width: 288px;
            font-size: 18px;
            font-weight: 500;
            color: #ffffff;
            line-height: 26px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
          }
          p:nth-of-type(2) {
            font-size: 14px;
            font-weight: 400;
            color: #b1c6ff;
            line-height: 22px;
            // margin-bottom: 50px;
            // display: -webkit-box;
            // -webkit-box-orient: vertical;
            // -webkit-line-clamp: 2;
            overflow: hidden;
          }
          p:nth-of-type(3) {
            font-size: 14px;
            font-weight: 400;
            color: #b1c6ff;
            line-height: 14px;
          }
        }
      }
    }
    .MORE {
      position: absolute;
      right: 0px;
      bottom: -20px;
      color: #fff;
      font-size: 12px;
      display: flex;
      cursor: pointer;
      img {
        margin-right: 4px;
      }
      &:hover {
        opacity: 0.8;
      }
    }
  }
}
.block4 {
  height: 359px;
  // background:url('../assets/images/img3.png') center/100% auto repeat-y;
  .con {
    width: 1200px;
    margin: 0 auto;
    padding-top: 100px;
    .title {
      height: 39px;
      margin: 0 auto;
    }
    .desc {
      margin-top: 64px;
      font-size: 16px;
      color: #b1c6ff;
      line-height: 28px;
      text-align: center;
    }
  }
}
.video {
  width: 100vw;
  height: 100vh;
  background: #000;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  .close {
    background: #fff;
    padding: 10px;
    width: 30px;
    border-radius: 50%;
    position: absolute;
    right: 40px;
    top: 40px;
    z-index: 1;
  }
  video {
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 1280px) {
  .top {
    // height: 803px;
    // position: relative;
    .poster {
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }
    .play {
      width: 64px;
      height: 64px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .block1 {
    // background:url('../assets/images/img1.jpg') center/100% auto repeat-y;
    min-height: 770px;
    // background: #2C3C6B;
    padding: 103px 0 60px;
    .con {
      width: 1385px;
      margin: 0 auto;
      .title {
        height: 74px;
        padding-top: 24px;
        font-size: 36px;
        color: #ffffff;
        line-height: 50px;
        letter-spacing: 4px;
        text-align: center;
      }
      ul {
        margin-top: 40px;
        display: flex;
        justify-content: space-around;
        width: 1240px;
        margin: 0 auto;
        li {
          // margin-right: 39px;
          margin-bottom: 20px;
          &:nth-of-type(4n + 4) {
            margin-right: 0;
          }
          .icon {
            width: 300px;
            height: 289px;
            object-fit: cover;
          }
          .desc {
            margin: 8px auto 0;
            width: 226px;
            height: 140px;
            font-size: 14px;
            font-weight: 400;
            color: #b1c6ff;
            line-height: 28px;
            overflow: hidden;
          }
        }
      }
    }
  }
  .block2 {
    min-height: 630px;
    // background: url('../assets/images/img2.jpg') center/100% auto repeat-y;
    padding: 100px 0 60px;
    .con {
      width: 1160px;
      margin: 0 auto;
      .title {
        height: 74px;
        padding-top: 24px;
        font-size: 36px;
        color: #ffffff;
        line-height: 50px;
        letter-spacing: 4px;
        text-align: center;
      }
      ul {
        margin-top: 60px;
        text-align: center;
        li {
          margin-right: 30px;
          margin-bottom: 30px;
          &:nth-of-type(5n + 5) {
            margin-right: 0;
          }
          width: 208px;
          height: 132px;
          position: relative;
          top: 0;
          transition: all 0.3s ease;
          &:hover {
            top: -10px;
            box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.5),
              0px 1px 45px 0px rgba(46, 75, 180, 0.42) inset;
            .li-con {
              border: 2px solid;
              border-image: linear-gradient(
                  225deg,
                  rgba(255, 255, 255, 0.46),
                  rgba(29, 43, 80, 0)
                )
                1 1;
              color: #fff;
            }
          }
          .filter {
            width: 100%;
            height: 100%;
            filter: blur(6px);
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
          }
          .li-con {
            width: 100%;
            height: 100%;
            background: linear-gradient(
              221deg,
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 0) 100%
            );
            box-shadow: 0px 1px 45px 0px rgba(46, 75, 180, 0.42) inset;
            border: 1px solid;
            border-image: linear-gradient(
                225deg,
                rgba(255, 255, 255, 0.46),
                rgba(29, 43, 80, 0)
              )
              1 1;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            color: #b1c6ff;
            .che {
              font-size: 20px;
              line-height: 44px;
            }
            .eng {
              font-size: 14px;
              line-height: 36px;
            }
          }
        }
      }
    }
  }
  .block3 {
    height: 622px;
    // background:url('../assets/images/l-b3-bg.png') no-repeat;
    position: relative;
    .c-con {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      padding-top: 75px;
      .c-big {
        width: 475px;
      }
      .abs1 {
        width: 227px;
        position: absolute;
        &:nth-of-type(1) {
          left: -266px;
          top: 157px;
        }
        &:nth-of-type(2) {
          left: -266px;
          top: 346px;
        }
        &:nth-of-type(3) {
          left: 523px;
          top: 242px;
        }
        .title {
          font-size: 20px;
          color: #ffffff;
          line-height: 30px;
          text-align: center;
        }
        .desc {
          margin-top: 12px;
          width: 227px;
          padding: 14px 19px;
          background: linear-gradient(
            221deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 100%
          );
          box-shadow: 0px 1px 45px 0px rgba(46, 75, 180, 0.42) inset;
          border: 1px solid;
          border-image: linear-gradient(
              225deg,
              rgba(255, 255, 255, 0.46),
              rgba(29, 43, 80, 0)
            )
            1 1;

          font-size: 14px;
          color: rgba(255, 255, 255, 0.8);
          line-height: 22px;
          font-weight: 400;
          color: #b1c6ff;
        }
      }
    }
  }

  .block35 {
    height: 779px;
    background: url(../assets/img01/bg_txt.png) center center / cover no-repeat;
    background-position: -2px 0px;
    .mar {
      width: 1172px;
      margin: 0 auto;
      position: relative;
      .text {
        width: 290px;
        font-size: 36px;
        font-weight: 400;
        color: #ffffff;
        line-height: 50px;
        letter-spacing: 4px;
        margin: 0 auto;
        padding-top: 124px;
        position: relative;
        img {
          width: 783px;
          height: 77px;
          position: absolute;
          bottom: -10px;
          left: calc(50% - 391.5px);
        }
      }
      .box_2 {
        width: 1172px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0 auto;
        margin-top: 55px;
        .sam_box {
          width: 574px;
          height: 210px;
          margin-bottom: 25px;
          display: flex;
          justify-content: space-between;
          img {
            width: 253px;
            height: 210px;
          }
          .txt {
            width: 322px;
            height: 209px;
            background: linear-gradient(
              43deg,
              #324876 0%,
              #1d2b50 100%,
              #1d2b50 100%
            );
            padding: 20px;
            p:nth-of-type(1) {
              width: 288px;
              font-size: 18px;
              font-weight: 500;
              color: #ffffff;
              line-height: 26px;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
            }
            p:nth-of-type(2) {
              font-size: 14px;
              font-weight: 400;
              color: #b1c6ff;
              line-height: 22px;
              // margin-bottom: 50px;
              // display: -webkit-box;
              // -webkit-box-orient: vertical;
              // -webkit-line-clamp: 2;
              overflow: hidden;
            }
            p:nth-of-type(3) {
              font-size: 14px;
              font-weight: 400;
              color: #b1c6ff;
              line-height: 14px;
            }
          }
        }
      }
      .MORE {
        position: absolute;
        right: 0px;
        bottom: -20px;
        color: #fff;
        font-size: 12px;
        display: flex;
        cursor: pointer;
        img {
          margin-right: 4px;
        }
      }
    }
  }

  .block4 {
    height: 359px;
    // background:url('../assets/images/img3.png') center/100% auto repeat-y;
    .con {
      width: 1200px;
      margin: 0 auto;
      padding-top: 100px;
      .title {
        height: 39px;
        margin: 0 auto;
      }
      .desc {
        margin-top: 64px;
        font-size: 16px;
        color: #b1c6ff;
        line-height: 28px;
        text-align: center;
      }
    }
  }
  .video {
    width: 100vw;
    height: 100vh;
    background: #000;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10;
    .close {
      background: #fff;
      padding: 10px;
      width: 30px;
      border-radius: 50%;
      position: absolute;
      right: 40px;
      top: 40px;
      z-index: 1;
    }
    video {
      width: 100%;
      height: 100%;
    }
  }
  .block_01 .con .text,  .block_02 .con .text,.block_03 .con .text{
    font-size: 36px;

  }
  .block_03 .con .text img,.block_01 .con .text img,.block_02 .con .text img{
    height: 77px;
  }
}

@media (max-width: 1367px) and (min-width: 1280px) {
  .block1 {
    // background:url('../assets/images/img1.jpg') center/100% auto repeat-y;
    min-height: calc(770px * 0.9);
    // background: #2C3C6B;
    padding: calc(103px * 0.9) 0 calc(60px * 0.9);
    .con {
      width: calc(1385px * 0.9);
      margin: 0 auto;
      .title {
        height: calc(74px * 0.9);
        padding-top: calc(24px * 0.9);
        font-size: 36px;
        color: #ffffff;
        line-height: calc(50px * 0.9);
        letter-spacing: calc(4px * 0.9);
        text-align: center;
      }
      ul {
        margin-top: calc(40px * 0.9);
        display: flex;
        justify-content: space-around;
        width: calc(1240px * 0.9);
        margin: 0 auto;
        li {
          // margin-right: calc(39px * 0.9);
          margin-bottom: calc(20px * 0.9);
          &:nth-of-type(4n + 4) {
            margin-right: 0;
          }
          .icon {
            width: calc(300px * 0.9);
            height: calc(289px * 0.9);
            object-fit: cover;
          }
          .desc {
            margin: calc(8px * 0.9) auto 0;
            width: calc(226px * 0.9);
            height: calc(140px * 0.9);
            font-size: 14px;
            font-weight: 400;
            color: #b1c6ff;
            line-height: calc(28px * 0.9);
            overflow: hidden;
          }
        }
      }
    }
  }
  .block2 {
    min-height: calc(630px * 0.9);
    // background: url('../assets/images/img2.jpg') center/100% auto repeat-y;
    padding: calc(100px * 0.9) 0 calc(60px * 0.9);
    .con {
      width: calc(1160px * 0.9);
      margin: 0 auto;
      .title {
        height: calc(74px * 0.9);
        padding-top: calc(24px * 0.9);
        font-size: 36px;
        color: #ffffff;
        line-height: calc(50px * 0.9);
        letter-spacing: 4px;
        text-align: center;
      }
      ul {
        margin-top: calc(60px * 0.9);
        text-align: center;
        li {
          margin-right: calc(30px * 0.9);
          margin-bottom: calc(30px * 0.9);
          &:nth-of-type(5n + 5) {
            margin-right: 0;
          }
          width: calc(208px * 0.9);
          height: calc(132px * 0.9);
          position: relative;
          top: 0;
          transition: all 0.3s ease;
          &:hover {
            top: -10px;
            box-shadow: 0px 0px 6px 0px rgba(255, 255, 255, 0.5),
              0px 1px 45px 0px rgba(46, 75, 180, 0.42) inset;
            .li-con {
              border: 2px solid;
              border-image: linear-gradient(
                  225deg,
                  rgba(255, 255, 255, 0.46),
                  rgba(29, 43, 80, 0)
                )
                1 1;
              color: #fff;
            }
          }
          .filter {
            width: 100%;
            height: 100%;
            filter: blur(6px);
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
          }
          .li-con {
            width: 100%;
            height: 100%;
            background: linear-gradient(
              221deg,
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 0) 100%
            );
            box-shadow: 0px 1px 45px 0px rgba(46, 75, 180, 0.42) inset;
            border: 1px solid;
            border-image: linear-gradient(
                225deg,
                rgba(255, 255, 255, 0.46),
                rgba(29, 43, 80, 0)
              )
              1 1;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            color: #b1c6ff;
            .che {
              font-size: 18px;
              line-height: calc(44px * 0.9);
            }
            .eng {
              font-size: 14px;
              line-height: calc(36px * 0.9);
            }
          }
        }
      }
    }
  }
  .block35 {
    height: calc(779px * 0.9);
    background: url(../assets/img01/bg_txt.png) center center / cover no-repeat;
    background-position: -2px 0px;
    .mar {
      width: calc(1172px * 0.9);
      margin: 0 auto;
      position: relative;
      .text {
        width: 290px;
        font-size: 36px;
        font-weight: 400;
        color: #ffffff;
        line-height: calc(50px * 0.9);
        letter-spacing: 4px;
        margin: 0 auto;
        padding-top: calc(124px * 0.9);
        position: relative;
        img {
          width: calc(783px * 0.9);
          height: calc(77px * 0.9);
          position: absolute;
          bottom: calc(-10px * 0.9);
          left: calc(50% - 391.5px);
        }
      }
      .box_2 {
        width: calc(1172px * 0.9);
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0 auto;
        margin-top: calc(55px * 0.9);
        .sam_box {
          width: calc(574px * 0.9);
          height: calc(210px * 0.9);
          margin-bottom: calc(25px * 0.9);
          display: flex;
          justify-content: space-between;
          cursor: pointer;
          &:hover {
            box-shadow: 0px 0px 13px 0px #038bc7;
            transition: all 0.3s linear;
          }
          img {
            width: calc(253px * 0.9);
            height: calc(210px * 0.9);
          }
          .txt {
            width: calc(322px * 0.9);
            height: calc(209px * 0.9);
            background: linear-gradient(
              43deg,
              #324876 0%,
              #1d2b50 100%,
              #1d2b50 100%
            );
            padding: calc(20px * 0.9);
            p:nth-of-type(1) {
              width: calc(288px * 0.9);
              font-size: 18px;
              font-weight: 500;
              color: #ffffff;
              line-height: calc(26px * 0.9);
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
            }
            p:nth-of-type(2) {
              font-size: 14px;
              font-weight: 400;
              color: #b1c6ff;
              line-height: calc(22px * 0.9);
              // margin-bottom: calc(50px * 0.9);
              // display: -webkit-box;
              // -webkit-box-orient: vertical;
              // -webkit-line-clamp: 2;
              overflow: hidden;
            }
            p:nth-of-type(3) {
              font-size: 14px;
              font-weight: 400;
              color: #b1c6ff;
              line-height: 14px;
            }
          }
        }
      }
      .MORE {
        position: absolute;
        right: 0px;
        bottom: calc(-20px * 0.9);
        color: #fff;
        font-size: 12px;
        display: flex;
        cursor: pointer;
        img {
          margin-right: 4px;
        }
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
  .block_01 .con .text{
    margin-bottom: 4vw;
  }
  .block_01 .con .text,  .block_02 .con .text,.block_03 .con .text{
    font-size: 36px;
height: 72px;
  }

  .block_01 .con .text img,
  .block_02 .con .text img,
  .block_03 .con .text img{
    top:10%
  }
  .block_01 .card-box .card-item .card-txt .te{
    font-size: 14px;

  }
  .block_01 .card-box .card-item.active .ts{
    font-size: 14px;
  }
  .block_01 .card-box .right,.block_01 .card-box .left{
    height: 27vw;
  }
  .block_02 .con .bottom .left .t1{
    font-size: 14px;

  }
  .block_03 .con .bottom .ul-box .li .show_txt .t2{
    font-size: 14px;

  }
  .block_03 .con .bottom .ul-box .li .hide_txt .t2{
    font-size: 14px;

  }

}
@media (min-width:996px) and(max-width:1140px) {
  .block_01 .card-box .left,.block_01 .card-box .right{
height: 280px;
  }
  .block_01 .card-box  .card-item.active,.block_01 .card-box .left .card-item.active{
  }
  .block_01 .card-box .card-item{
    height: 15vw  !important;
  }
  .block_01 .card-box .right .card-item.active{
    height: 200px !important;
  }
  .block_01 .card-box .card-item:hover{
    height: 200px !important;

  }
  .block_01 .card-box .right,.block_01 .card-box .left{
    height: 27vw;
  }
  .block_02 .con .bottom .left .t1{
    font-size: 14px;

  }
  .block_01 .card-box .card-item .card-txt .te{
    font-size: 14px;

  }
  .block_01 .card-box .card-item.active .ts{
    font-size: 14px;
  }
  .block_03 .con .bottom .ul-box .li .show_txt .t2{
    font-size: 14px;

  }
  .block_03 .con .bottom .ul-box .li .hide_txt .t2{
    font-size: 14px;

  }
}


</style>